export interface IItem {
    question: string
    answer: {
        text: string
        ticket: boolean
    }[]
}


export const items = [
    {
        question: "What is DegenPePeInu",
        answer: [
            {
                text: "This is a community based ",
                ticket: false
            },
            {
                text: "crypto meme",
                ticket: true
            },
            {
                text: " project that honors the true crypto degens that love their pepe and shiba inu and are hodling strong too the moon!",
                ticket: false
            },
        ]
    },
    {
        question: "What is DegenPePeInu",
        answer: [
            {
                text: "This is a community based ",
                ticket: false
            },
            {
                text: "crypto meme",
                ticket: true
            },
            {
                text: " project that honors the true crypto degens that love their pepe and shiba inu and are hodling strong too the moon!",
                ticket: false
            },
        ]
    },
    {
        question: "What is DegenPePeInu",
        answer: [
            {
                text: "This is a community based ",
                ticket: false
            },
            {
                text: "crypto meme",
                ticket: true
            },
            {
                text: " project that honors the true crypto degens that love their pepe and shiba inu and are hodling strong too the moon!",
                ticket: false
            },
        ]
    },
    {
        question: "What is DegenPePeInu",
        answer: [
            {
                text: "This is a community based ",
                ticket: false
            },
            {
                text: "crypto meme",
                ticket: true
            },
            {
                text: " project that honors the true crypto degens that love their pepe and shiba inu and are hodling strong too the moon!",
                ticket: false
            },
        ]
    }
]