export const data = [
    {
        transaction: "JU2545PK...0C791",
        date: "29.02.24",
        amount: "$1,674,600",
        status: "in progress"
    },
    {
        transaction: "JU2545PK...0C791",
        date: "29.02.24",
        amount: "$1,674,600",
        status: "rejected"
    },
    {
        transaction: "JU2545PK...0C791",
        date: "29.02.24",
        amount: "$1,674,600",
        status: "completed"
    },
    {
        transaction: "JU2545PK...0C791",
        date: "29.02.24",
        amount: "$1,674,600",
        status: "in progress"
    },
    {
        transaction: "JU2545PK...0C791",
        date: "29.02.24",
        amount: "$1,674,600",
        status: "completed"
    },
]